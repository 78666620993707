<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<br>

<div class="about-area ">
    <div class="container">
        <div class="row " *ngFor="let item of about">
            <h2 style="text-align: center;"><b><u>{{title}}</u></b></h2>
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <!-- <span>Get know</span> -->
                        <p style="text-align: justify;"><b><span
                                    style="color:brown">&#10132;</span>&nbsp;&nbsp;{{item.para1}}</b>
                        </p>
                        <p style="text-align: justify;"><b><span
                                    style="color:brown">&#10132;</span>&nbsp;&nbsp;{{item.para2}}</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="{{item.image}}" alt="image">
                </div>
            </div>
            <div class="col-lg-12">
                <div class="about-content">
                    <div class="section-title">
                        <!-- <span>Get know</span> -->
                        <p style="text-align: justify;"><b><span
                                    style="color:brown">&#10132;</span>&nbsp;&nbsp;{{item.para3}}</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="video-area video-area-bg">
    <div class="container">
        <div class="video-content">
            <h2>Are You Ready To Start Your Journey?</h2>
            <a href="https://www.youtube-nocookie.com/embed/sJ9tI298RW4" class="play-btn"><i class='bx bx-play'></i></a>
        </div>
    </div>
</div>
<br>
<section id="advertisers" class="advertisers-service-sec pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="section-header text-center">
                <h2 class="fw-bold fs-1">
                    Use
                    <span class="b-class-secondary">Quick Search </span>By Category
                </h2>
                <p class="sec-icon"><i class="fa-solid fa-gear"></i></p>
            </div>
        </div>
        <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
            <div class="col">
                <div class="service-card">
                    <div class="icon-wrapper">
                        <i class="flaticon-bake"></i>
                    </div>
                    <h3>Restaurant</h3>
                    <p style="text-align: justify;">
                        If you're looking for a relaxed laid back brunch then araku coffee is one of the beautiful cafés
                        in Indiranagar you should explore .
                    </p>
                </div>
            </div>
            <div class="col">
                <div class="service-card">
                    <div class="icon-wrapper">
                        <i class="flaticon-hotel"></i>
                    </div>
                    <h3>Hotel & Resort</h3>
                    <p style="text-align: justify;">
                        Book Your Hotel In Araku Online. No Reservation Costs. Great Rates. Book Your Hotel In Araku
                        Online. No Reservation Costs. Great Rates. Great Choice. 24/7 Customer Service.
                    </p>
                </div>
            </div>
            <div class="col">
                <div class="service-card">
                    <div class="icon-wrapper">
                        <img src="assets/img/shape/tent (2).png">
                        <!-- <i class="flaticon-barbell"></i> -->
                    </div>
                    <h3>Camping Tents</h3>
                    <p style="text-align: justify;">
                        The property offers a variety of spacious Tents that can accommodate 2-6 adults and feature a
                        room size of 400sq. ft.
                    </p>
                </div>
            </div>
            <div class="col">
                <div class="service-card">
                    <div class="icon-wrapper">
                        <i class="flaticon-store"></i>
                    </div>
                    <h3>Mart</h3>
                    <p style="text-align: justify;">
                        The Tribal Museum covers a huge area but most of the area has shops selling tribal arts and
                        crafts, most of them selling the same or similar things.
                    </p>
                </div>
            </div>
            <div class="col">
                <div class="service-card">
                    <div class="icon-wrapper">

                        <i class="flaticon-event"></i>
                    </div>
                    <h3>Events & Programme</h3>
                    <p style="text-align: justify;">
                        The main event of the Araku Balloon Festival, these rides give you an opportunity to witness the
                        stunning valley, its vast green space, and surrounding hills - all from a bird's eye view.
                    </p>
                </div>
            </div>
            <div class="col">
                <div class="service-card">
                    <div class="icon-wrapper">
                        <!-- <i class="flaticon-flower"></i> -->

                        <img src="assets/img/destination (2).png">
                    </div>
                    <h3>Destinations </h3>
                    <p style="text-align: justify;">
                        Araku Valley is a hill station in Alluri Sitharama Raju district in the Indian state of Andhra
                        Pradesh, lying 111 km west of Visakhapatnam city..
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ADVERTISERS SERVICE CARD ENDED -->
<br><br>
<app-footer-style-two></app-footer-style-two>