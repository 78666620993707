import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeOneComponent implements OnInit {
  gethotelalls: any;
  getallimage: any;
  now: any;
  submitform: any;
  submitted = false;
  banners: any;
  location: any;
  locationsss: any = [];
  cra: any = [];
  locationdetails: any;

  constructor(private service: ServiceService, private formBuilder: FormBuilder, private router: Router, private modalService: NgbModal) {

    // window.location.href = 'https://arakutourismart.com/paymentinfo/sample_file.html'

    if (localStorage.getItem("modalid") == null) {
      this.modal();
      localStorage.setItem("modalid", "1")
    }

  }
  bannerOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      }
    },
    // nav: true
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }
  testimonialOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  }
  ngOnInit(): void {
    this.getlocations();
    this.submitform = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
      todate: ['', [Validators.required]],
      persons: [''],
      childerns: ['',],
      location_id: [''],
    });
    // this.gethotelall();
    this.getallimages();
    const datePipe = new DatePipe('en-Us');
    this.now = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getbanners();
    this.gethotelslocation();
  }

  gethotelslocation() {
    this.service.gethotelslocation().subscribe((res: any) => {
      this.locationdetails = res.data;
     

    })
  }








  modal() {
    Swal.fire({
      title: 'Welcome To ArakuTourismart.Com',
      text: 'Araku Valley is a beautiful hill station located in the Eastern Ghats of Andhra Pradesh, India.',
      imageUrl: 'assets/img/blog/power-banks.png',
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
    })
  }

  getbanners() {
    this.service.gethomewebbanners().subscribe((res: any) => {
      this.banners = res.data;
    })
  }
  getallimages() {
    this.service.getbannershome().subscribe((res: any) => {
      this.getallimage = res.data;
    })
  }

  login: boolean = false;

  gotorooms() {

    this.login = true;
    this.submitted = true;
    if (this.submitform.invalid) {
      return;
    } else {
      var data = {
        fromdate: this.submitform.value.fromdate,
        todate: this.submitform.value.todate,
        persons: this.submitform.value.persons,
        childerns: this.submitform.value.childerns,
        location_id: this.submitform.value.location_id,
      }
    
      this.router.navigate(['/team'], { queryParams: { fromdate: data.fromdate, todate: data.todate, persons: data.persons, location_id: data.location_id } });
    }
  }

  // validation

  get f() { return this.submitform.controls; }
  getlocations() {
  }


  showpdfdata(modal) {


  }



}
