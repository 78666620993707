<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Profile</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Profile</li>
            </ul>
        </div>
    </div>
</div>

<div class="user-area">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img">
                    <img src="assets/img/bg (4).png" alt="Images">
                </div>
            </div>
            <div class="col-lg-5 col-xl-6">
                <div class="user-section text-center">
                    <div class="user-content">
                        <h2>Welcome <b>To ArakuTourismart.Com</b></h2>
                    </div>
                    <div class="tab user-tab">
                        <ul class="tabs">
                            <li><a href="#"> <i class="flaticon-contact"></i>Profile</a></li>
                            <li><a href="#"> <i class="flaticon-verify"></i>My Booking</a></li>
                        </ul>
                        <div class="tab_content current active">
                            <div class="tabs_item current">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form [formGroup]="submitform">

                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="name" [(ngModel)]="name"
                                                            class="form-control" required readonly
                                                            data-error="Please enter your Fullname"
                                                            placeholder="Fullname">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bxs-phone'></i>
                                                        <input type="text" formControlName="number" [(ngModel)]="number"
                                                            readonly class="form-control" required
                                                            placeholder="Phone Number" maxlength="10" minlength="10">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='flaticon-email-2'></i>
                                                        <input type="email" formControlName="email" [(ngModel)]="email"
                                                            class="form-control" required readonly
                                                            data-error="Please enter your email" placeholder="E-mail">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" (click)="logout()"
                                                        class="default-btn  btn-danger user-all-btn">Logout !
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="user-all-form">
                                    <div *ngIf="nohistory" class="contact-form">
                                        <br><br><br><br><br>
                                        <p>No Booking Here</p>
                                    </div>
                                    <section class="light" *ngIf="!nohistory" style="height:600px;overflow:scroll;">
                                        <div class="container py-2" *ngFor="let i of getticketsdata">
                                            <article class="postcard light ">
                                                <a class="postcard__img_link" href="#">
                                                    <img class="postcard__img" src={{i.image}} alt="Image Title" />
                                                </a>
                                                <div class="postcard__text t-dark">
                                                    <h3 class="postcard__title ">Hotel : {{i.name}}</h3>
                                                    <div class="postcard__subtitle small">
                                                        <time datetime="2020-05-25 12:00:00">
                                                            <i class="fas fa-calendar-alt mr-2"></i><u>Booking Date</u>
                                                            : {{i.i_ts | date }} at {{i.i_ts | date : 'hh:mm'}}
                                                        </time>
                                                    </div>
                                                    <div class="postcard__bar"></div>
                                                    <div class="postcard__preview-txt">
                                                        <!-- Room Type : {{i.roomtype}} <br> -->
                                                        Check-in : {{i.fromdate | date }} <br>
                                                        Check-out : {{i.todate | date }} <br>
                                                        No of persons : {{i.noofperson}}
                                                    </div>


                                                    <ul class="postcard__tagbox">
                                                        <li>
                                                            <button class="btn btn-success" *ngIf="i.status_ind==1">
                                                                Booked</button>
                                                            <button class="btn btn-danger"
                                                                *ngIf="i.status_ind==2">Failed</button>
                                                            <button class="btn  btn-warning"
                                                                *ngIf="i.status_ind==3">Cancelled</button>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </article>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>