<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Hotel Bookings</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Hotel booking</li>
            </ul>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<br>
<div class="team-area ">
    <div class="container" *ngIf="finalarray.length">
        <div class="row" id="container" *ngFor="let item of finalarray">
            <div class="col-lg-6">
                <div class="product-image">
                    <img src="{{item.image}}" alt="">
                    <div class="info">
                        <h2> Description</h2>
                        <ul>
                            <li *ngFor="let i of item.amentie">
                                <strong>{{i.amentie}}</strong>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="product-details">
                    <h1>{{item.name}}</h1>
                    <br>
                    <span class="hint-star star">
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <span style="margin-left: 2%;color: rgb(210, 113, 113);">4.2 (Rating)</span>
                    </span>
                    <br>
                    <div class="persuasion__item pc__peitho">
                        <i class="fa fa-question-circle-o" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;&nbsp;<span>50+
                            guests like you rated their stay Good</span>
                    </div>
                    <p class="information"> <i class="fa fa-compass" aria-hidden="true"></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;{{item.address}}</p>
                    <div class="control">
                        <button class="btn">
                            <span class="price">Rs .{{item.price_perday}}</span>

                            <span class="buy" (click)="gotorooms(item)">Book Now</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="finalarray.length==0">
        <p style="text-align: center; font-size: 42px;  padding: 6%;">
            No Hotels in this Location.
        </p>
    </div>

</div>
<app-footer-style-two></app-footer-style-two>