import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';
import Swal from 'sweetalert2';
declare var Razorpay: any;  //rezorpay 

declare global {
  interface Window {
    PGSecurePay: {
      getOcRequestId:
      (params: { totalAmount: string; currency: string }) => string;
    };
  }
}

@Component({
  selector: 'app-bookingform',
  templateUrl: './bookingform.component.html',
  styleUrls: ['./bookingform.component.scss'],
  providers: [DatePipe]
})
export class BookingformComponent {
  datepicked: any;
  submitform: any;
  submitted = false;
  now: any;
  booking_hotel_name:any;
  todate: any;
  hotel_id: any;
  fromdate: any;
  rooms: any;
  viewMode = 'tab1';
  statusClass = 'not-active';
  name: any;
  number: any;
  email: any;
  persons: any;
  childerns: any;
  days: any;
  mymoney: any;
  main_amount: any;
  imagesr: any;
  masterdata: any = [];
  options: any = [];
  hotel_price: any
  show: boolean = false;
  ocRequestId: any

  showSpinner: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private service: ServiceService, private route: ActivatedRoute,) {

    this.route.queryParams.subscribe(params => {

      this.fromdate = params['fromdate'];
      this.todate = params['todate'];
      this.hotel_id = params['hotel_id'];
      this.booking_hotel_name = params['booking_hotel_name']
      this.persons = '2';
      this.childerns = '0';
    });
    this.name = localStorage.getItem('name');
    this.number = localStorage.getItem('mobileno');
    this.email = localStorage.getItem('email');
  }

  ngOnInit(): void {
    this.datepicked = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
    })
    this.submitform = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
      todate: ['', [Validators.required]],
      days: ['',],
      name: ['', [Validators.required]],
      // age: ['', [Validators.required]],
      number: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      persons: ['', [Validators.required]],
      childerns: [''],
      // taxamount:['']
      // amount: ['', [Validators.required]],
    });
    this.getsinglehotel();
    this.getrooms();
    const datePipe = new DatePipe('en-Us');
    this.now = datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  setActiveClass() {
    this.statusClass = 'active';
  }

  get f() { return this.submitform.controls; }

  numericOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  notallowquotes(event: any): boolean {
    let patt = /^[ a-zA-Z0-9_,/-]*$/;
    let result = patt.test(event.key);
    return result;
  }

  diffdays() {
    if (this.fromdate == undefined) {
      alert('Please select the Check In and Check Out ')
    } else if (this.fromdate == this.todate) {
      alert('Please select the another date')
      this.days = '';
    } else {
      this.persons = '2';
      this.childerns = '0';
      var startDate = new Date(this.fromdate);
      var endDate = new Date(this.todate);

      var Time = endDate.getTime() - startDate.getTime();
      this.days = Time / (1000 * 3600 * 24);
      this.total_amount = (this.days * 1) * (this.hotel_price * 1)
      this.main_amount = (this.days * 1) * (this.hotel_price * 1)
    }
  }

  addq() {
    this.show = false;
    if (this.fromdate == '' || this.fromdate == null || this.fromdate == undefined) {
      alert('Please Select the dates')
    } else {
      this.persons = (this.persons * 1) + 1
      var personamount = (300 * 1) * (this.days * 1);
      this.total_amount = (this.total_amount * 1) + (personamount * 1)
      this.mymoney = (this.total_amount * 1) + (personamount * 1)
    }
  }

  adds() {
    this.show = false;
    if (this.fromdate == '' || this.fromdate == null || this.fromdate == undefined) {
      alert('Please Select the dates')
    } else {
      this.childerns = (this.childerns * 1) + 1
      var childrenamount = (150 * 1) * (this.days * 1);
      this.total_amount = (this.total_amount * 1) + (childrenamount * 1)
      this.mymoney = (this.total_amount * 1) + (childrenamount * 1)
    }
  }

  minus() {
    this.show = false;
    if (this.persons == 2) {
      alert("Availiability of 2 persons")
    } else {
      this.persons = (this.persons * 1) - 1;
      var personamount = (300 * 1) * (this.days * 1);
      this.total_amount = (this.total_amount * 1) - (personamount * 1)
    }
  }

  less() {
    this.show = false;
    if (this.childerns == '0') {
      alert("Availiability of 1 childerns")
    } else {
      this.childerns = (this.childerns * 1) - 1;
      var childrenamount = (150 * 1) * (this.days * 1);
      this.total_amount = (this.total_amount * 1) - (childrenamount * 1)
    }
  }

  taxamount: any;



  getrooms() {
    var data = {
      hotel_id: this.hotel_id
    }
    this.service.gethotelroom(data).subscribe((res: any) => {
      this.rooms = res.data;
    
    })
  }
  hotels: any
  rules: any = []
  overview: any = []
  cancelationpolicy: any = []
  accesability: any = []
  bedpolicy: any = []
  consumptionrules: any = []
  foodarrangements: any = []
  hotel_name: any = []
  total_amount: any = []

  getsinglehotel() {
    var data = {
      hotel_id: this.hotel_id
    }
    this.service.getsinglehotel(data).subscribe((res: any) => {
      this.hotels = res.data;
      this.overview = JSON.parse(this.hotels[0].overview);
      this.rules = JSON.parse(this.hotels[0].rules);
      this.cancelationpolicy = JSON.parse(this.hotels[0].cancelationpolicy);
      this.accesability = JSON.parse(this.hotels[0].accesability);
      this.bedpolicy = JSON.parse(this.hotels[0].bedpolicy);
      this.consumptionrules = JSON.parse(this.hotels[0].consumptionrules);
      this.foodarrangements = JSON.parse(this.hotels[0].foodarrangements);
      this.hotel_name = res.data[0].name;
      this.total_amount = res.data[0].price_perday;
      this.hotel_price = res.data[0].price_perday
      if (res.data[0].image == null) {
        res.data[0].image = "assets/img/download.png";
      }
      if (res.data[0].image2 == null) {
        res.data[0].image2 = "assets/img/download.png";
      }
      if (res.data[0].image3 == null) {
        res.data[0].image3 = "assets/img/download.png";
      }
      if (res.data[0].image4 == null) {
        res.data[0].image4 = "assets/img/download.png";
      }
      if (res.data[0].image5 == null) {
        res.data[0].image5 = "assets/img/download.png";
      }
      if (res.data[0].image6 == null) {
        res.data[0].image6 = "assets/img/download.png";
      }
      if (res.data[0].image7 == null) {
        res.data[0].image7 = "assets/img/download.png";
      }
      if (res.data[0].image8 == null) {
        res.data[0].image8 = "assets/img/download.png";
      }
      if (res.data[0].image9 == null) {
        res.data[0].image9 = "assets/img/download.png";
      }
      if (res.data[0].image10 == null) {
        res.data[0].image10 = "assets/img/download.png";
      }

      this.masterdata = [res.data[0].image, res.data[0].image2, res.data[0].image3, res.data[0].image4, res.data[0].image5, res.data[0].image6, res.data[0].image7, res.data[0].image8, res.data[0].image9, res.data[0].image10];

      

      if (res.status == 200) {
        this.diffdays();
      } else {
        alert('server down')
      }
    },
      error => {
      })
  }



  login: boolean = false;
  checklogin() {
    if ((localStorage.getItem('usr_id')) == null) {
      this.login = false;
    } else {
      this.login = true;
    }
  }
  alert() {
    alert('please Login')
  }
  ////////////////razorpay E:\My Projects\aaraku project\aaraku\website\src\assets\img\logo\Footer-Logo.png
  amount: any;
  grand_total: any;

  rezorpay() {
    if (this.submitform.invalid) {
      alert("please fill the Correct Details");
      return;
    } else {
      this.taxamount = ((this.total_amount / 100) * 9);
      var tax = (this.taxamount * 1) * 2
      this.grand_total = (this.total_amount * 1) + (tax * 1)
      this.show = true
    }
  }



  bookappointment() {
    this.submitted = true;
    if (this.submitform.invalid) {
      Swal.fire('Please fill the empty Fields')
      return;
    } else {
      this.showSpinner = true;
      var data = {
        amountds: Math.round((this.grand_total * 1 + 0 * 1) * 100),
        key: "rzp_live_DnY2yQRWoFHysh",
        secret_key: "cIMLql4pwmy7e16ETLzur94H",
        razorpay_id: "failure",
        days: this.submitform.value.days,
        number: this.submitform.value.number,
        email: this.submitform.value.email,
        noofperson: this.submitform.value.persons,
        childerns: this.submitform.value.childerns,
        amount: this.grand_total,
        fromdate: this.fromdate,
        todate: this.todate,
        hotel_id: this.hotel_id,
        hotel_booking_name:this.booking_hotel_name,
        usr_id: localStorage.getItem('usr_id'),
        name: localStorage.getItem('name'),
        payment_ind: "2",
      }
      console.log(data);
      this.service.getrazarpaydetails(data).subscribe((res: any) => {
        this.options = {
          "name": "Araku Tourismart",
          "description": "Online Payment",
          "image": "../../assets/img/logo/Footer-Logo.png",
          "order_id": res.orderId.id,
          "handler": (transaction: any, response: any) => {
            this.ticketconfrimrazor(transaction.razorpay_payment_id, "1", res.insertId)
          },
          "prefill": {
            "name": localStorage.getItem('name'),
            "contact": this.submitform.value.number
          },
          "notes": {
            "number": this.submitform.value.number,
            "amount": this.grand_total,
            "email": this.submitform.value.email,
            "address": "araku",

          },
          "theme": {
            "color": "#106164"
          },
          modal: {
            ondismiss: () => {
              this.cancelled();
            }
          }
        };
        var rzp1 = new Razorpay(this.options);
        rzp1.open();
      },
        error => {
          this.showSpinner = false;
        })

    }
  }
  cancelled() {
    Swal.fire('Payment Cancelled')
    this.submitform.reset();
    this.router.navigate(['/team']);
  }
  ticketconfrimrazor(razorpay_id: any, payment_ind: any, id: any) {
   
    var data = {
      razorpay_id: razorpay_id,
      id: id,
      status_ind: 1
    }
    this.service.updateRazarpaydailyStatus(data).subscribe((res: any) => {
       this.showSpinner = false;
      if (res.status == 200) {
        if (payment_ind == 1) {
        Swal.fire(
          'Ticket Confrimed .. !',
          'success'
        );
        this.submitform.reset();
        location.replace("profile")
        
      }
      }else{
        alert('Invalid Data');
        this.router.navigate(['/team']);
      }
     
    },
      error => {
        this.showSpinner = false;
      })
  }



  // ticketconfrim() {
  //   if ((localStorage.getItem('usr_id')) == null) {
  //     alert('please login');
  //     this.router.navigate(['/login-register']);
  //   } else {

  //     this.ocRequestId = window.PGSecurePay.getOcRequestId(
  //       { totalAmount: this.grand_total, currency: 'INR' }
  //     )

  //     var data = {
  //       days: this.submitform.value.days,
  //       number: this.submitform.value.number,
  //       email: this.submitform.value.email,
  //       persons: this.submitform.value.persons,
  //       childerns: this.submitform.value.childerns,
  //       amount: this.grand_total,
  //       fromdate: this.fromdate,
  //       todate: this.todate,
  //       hotel_id: this.hotel_id,
  //       usr_id: localStorage.getItem('usr_id'),
  //       name: localStorage.getItem('name'),
  //       ocRequestId: this.ocRequestId
  //     }
  //     console.log(data);

  //     this.service.getpayment(data).subscribe((res: any) => {
  //       window.open(res.data.data.redirectUrl, "_self");
  //     })
  //   }
  // }


  activeTab: number = 1;

  activateTab(tabNumber: number) {
    this.activeTab = tabNumber;
  }



}

