import { Component } from '@angular/core';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-dhmisadance',
  templateUrl: './dhmisadance.component.html',
  styleUrls: ['./dhmisadance.component.scss']
})
export class DhmisadanceComponent {
  getdance: any
  constructor(private service: ServiceService) {

  }

  ngOnInit(): void {
    this.getdhimsadance();
  }

  getdhimsadance() {
    this.service.getdhimsadance().subscribe((res: any) => {
  
      this.getdance = res.data;
     

    })
  }
}
