<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/bestway" class="logo"><img src="assets/img/logo/Footer-Logo.png" alt="Logo"></a>
    </div>
    <div class="main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/bestway"><img src="assets/img/logo/HEADERL.png" alt="Logo"></a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">

                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home </a></li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item">
                            <a class="nav-link" *ngIf="login" routerLink="/login-register">HotelBookings</a>
                            <a routerLink="/team" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}" *ngIf="notlogin">HotelBookings</a>
                        </li>

                        <li class="nav-item"><a routerLink="/destination" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Destinations</a></li>

                        <li class="nav-item"><a routerLink="/packages" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Packages</a></li>

                        <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">VehicleBookings</a></li>

                        <li *ngIf="notlogin" class="nav-item"><a routerLink="/profile" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Profile</a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">More</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/dhmisadance" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dhimsa
                                        dance</a></li>
                                <!-- <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Vehicel bookings</a></li> -->
                                <li class="nav-item"><a routerLink="/listing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                                <li class="nav-item"><a routerLink="/campingtents" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Camping
                                        Tents</a></li>



                                <li class="nav-item"><a routerLink="/bestway" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Best Way</a></li>
                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="isPlaying" style="width:25px;" (click)="togglePlayback()"><img
                                    src="assets/img/unmute.png">
                            </a>
                            <a class="nav-link" *ngIf="!isPlaying" style="width:25px;" (click)="togglePlayback()"><img
                                    src="assets/img/unmuteo.png"></a>

                            <audio #audioPlayer [src]="audioUrl"></audio>
                        </li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">

                        <div class="side-item">
                            <div class="nav-add-btn" *ngIf="login">
                                <a routerLink="/login-register" class="fth border-radius">Login </a>
                            </div>
                            <div *ngIf="notlogin">
                                <a class="loginbt2" (click)="logout()">User: {{name}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner"><br>
                    <p *ngIf="isPlaying" style="width:35px;margin-top: 22%;" (click)="togglePlayback()"><img
                            src="assets/img/unmute.png">
                    </p>
                    <p *ngIf="!isPlaying" style="width:35px;margin-top: 22%;" (click)="togglePlayback()"><img
                            src="assets/img/unmuteo.png"></p>
                    <audio #audioPlayer [src]="audioUrl"></audio>
                    &nbsp;
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">

                        <div class="side-item">

                            <div class="user-btn" *ngIf="login">
                                <a routerLink="/login-register"><i class="flaticon-contact"></i></a>
                            </div>
                            <div *ngIf="notlogin">
                                <a class="loginbt2" (click)="logout()">User: {{name}}</a>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>
        </div>
    </div>
</div>