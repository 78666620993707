import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

var apis = 'https://arakutourismart.com:9781/dashboardapi/'

// var apis = 'http://localhost:1430/dashboardapi/';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  headers: any;
  constructor(private httpClient: HttpClient) {

    this.headers = new HttpHeaders()
      .set('x-gl-auth', 'cGxhcmFrdXRvdXJpc21hOjYwYjBkZWZlLTVmMWYtNDEwZS05ZmU2LTcxNDBlNTM0MDc1YQ==');
  }

  public loingstatus = new BehaviorSubject(0);
  getloginstatus = this.loingstatus.asObservable();
  getlgstatus(data: any) {
    this.loingstatus.next(data)
  }


  getCatenamedata() {
    return this.httpClient.get(apis + 'getCatenamedata')
  }
  getgallerydata11() {
    return this.httpClient.get(apis + 'getgallerydata11')
  }
  gethotelall(data) {
  
    return this.httpClient.post(apis + 'gethotelall', data)
  }
  getallimages() {
    return this.httpClient.get(apis + 'getallimages')
  }
  getbannershome() {
    return this.httpClient.post(apis + 'gethomewebbanners', [])
  }
  getallviechles() {
    return this.httpClient.get(apis + 'getviechlesdata')
  }
  getdhimsadance() {
    return this.httpClient.get(apis + 'getdhimsadance')
  }
  postsignup(data) {
    return this.httpClient.post(apis + 'postwebsitesignup', data)
  }
  passwordlogin(data) {
    return this.httpClient.post(apis + 'passwordwebsitelogin', data)
  }
  getabout() {
    return this.httpClient.get(apis + 'getaboutwebsite')
  }
  getdistination() {
    return this.httpClient.get(apis + 'getdistination')
  }
  getpackages() {
    return this.httpClient.get(apis + 'getpackages')
  }
  postbookinghotel(data: any) {
    return this.httpClient.post(apis + 'postbookhotelwebsite', data)
  }
  gethotelroom(data) {
    return this.httpClient.post(apis + 'gethotelroom', data)
  }
  gatcampingtents() {
    return this.httpClient.get(apis + 'gatcampingtents')
  }
  getsinglehotel(data) {
    return this.httpClient.post(apis + 'getsinglehotel', data)
  }
  getticketshistory(data) {
    return this.httpClient.post(apis + 'getticketshistory', data)
  }
  getbanners() {
    return this.httpClient.post(apis + 'getbanners', [])
  }
  gethomewebbanners() {
    return this.httpClient.get(apis + 'gethomewebbanners')
  }
  getcountapi() {
    return this.httpClient.get('https://api.countapi.xyz/hit/araku/tourism').pipe(map(res => {
      return res;
    }));
  }
  //prasad//
  checkloginnumber(data) {
    return this.httpClient.post(apis + 'checkloginnumber', data)
  }
  submitotp(data) {
    return this.httpClient.post(apis + 'submitotp', data);
  }
  forgetpassword(data) {
    return this.httpClient.post(apis + 'forgetpassword', data);
  }
  addcontactus(data) {
    return this.httpClient.post(apis + 'contactuswebsite', data);
  }
  getappvehicles() {
    return this.httpClient.post(apis + 'getappvehicles', []);
  }
  postbookvehiclenow(data) {
    return this.httpClient.post(apis + 'postbookvehiclenow', data);
  }
  postpackagerequest(data) {
    return this.httpClient.post(apis + 'postpackagerequest', data);
  }
  gethotelslocation() {
    return this.httpClient.get(apis + 'getlocationsdata');
  }
  gethotelallssss() {
    return this.httpClient.get(apis + 'gethotelallssss');
  }
  getpricepackages() {
    return this.httpClient.post(apis + 'getpricepackages ', []);
  }

  getpayment(data) {
    return this.httpClient.post(apis + 'arakupayment', data);
  }

  getpaymnetdetials(data) {
    return this.httpClient.post(apis + 'getpaymnetdetials', data);
  }

  checkstatus(data) {
    return this.httpClient.post(apis + 'getcheckstatus', data);
  }


  getrazarpaydetails(data) {
    return this.httpClient.post(apis + 'getrazarpaydetails', data);
  }

  updateRazarpaydailyStatus(data) {
    return this.httpClient.post(apis + 'updateRazarpaydailyStatus', data);
  }



}



