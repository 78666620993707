import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  gethotelalls: any;
  fromdate: any;
  todate: any;
  persons: any;
  location_id: any;
  constructor(private service: ServiceService, private route: ActivatedRoute, private router: Router) {

    this.route.queryParams.subscribe(params => {
      this.fromdate = params['fromdate'];
      this.todate = params['todate'];
      this.persons = params['persons'];
      this.location_id = params['location_id'];
    });
  }

  ngOnInit(): void {
    this.gethotelall();
    this.gethotelallsss();
  }


  finalarray: any;

  gethotelall() {
    var data = {
      location_id: this.location_id
    }
    this.service.gethotelall(data).subscribe((res: any) => {
      this.gethotelalls = res.data;
      this.finalarray = []
      for (var i = 0; i < this.gethotelalls.length; i++) {
        this.finalarray.push({ 'i': this.gethotelalls[i].id, 'name': this.gethotelalls[i].name, 'mrpprice': this.gethotelalls[i].mrpprice, 'price_perday': this.gethotelalls[i].price_perday, 'address': this.gethotelalls[i].address, 'url': this.gethotelalls[i].url, 'image': this.gethotelalls[i].image, 'image2': this.gethotelalls[i].image2, 'image3': this.gethotelalls[i].image3, 'image4': this.gethotelalls[i].image4, 'overview': JSON.parse(this.gethotelalls[i].overview), 'amentie': JSON.parse(this.gethotelalls[i].amentie), 'foodarrangements': JSON.parse(this.gethotelalls[i].foodarrangements), 'consumptionrules': JSON.parse(this.gethotelalls[i].consumptionrules), 'accesability': JSON.parse(this.gethotelalls[i].accesability), 'bedpolicy': JSON.parse(this.gethotelalls[i].bedpolicy), 'rules': JSON.parse(this.gethotelalls[i].rules), 'cancelationpolicy': JSON.parse(this.gethotelalls[i].cancelationpolicy), 'activestatus': this.gethotelalls[i].activestatus });
      }
    })
  }

  gethotelallsss() {
    this.service.gethotelallssss().subscribe((res: any) => {
      this.gethotelalls = res.data;
      this.finalarray = []
      for (var i = 0; i < this.gethotelalls.length; i++) {
        this.finalarray.push({ 'i': this.gethotelalls[i].id, 'name': this.gethotelalls[i].name, 'mrpprice': this.gethotelalls[i].mrpprice, 'price_perday': this.gethotelalls[i].price_perday, 'address': this.gethotelalls[i].address, 'url': this.gethotelalls[i].url, 'image': this.gethotelalls[i].image, 'image2': this.gethotelalls[i].image2, 'image3': this.gethotelalls[i].image3, 'image4': this.gethotelalls[i].image4, 'overview': JSON.parse(this.gethotelalls[i].overview), 'amentie': JSON.parse(this.gethotelalls[i].amentie), 'foodarrangements': JSON.parse(this.gethotelalls[i].foodarrangements), 'consumptionrules': JSON.parse(this.gethotelalls[i].consumptionrules), 'accesability': JSON.parse(this.gethotelalls[i].accesability), 'bedpolicy': JSON.parse(this.gethotelalls[i].bedpolicy), 'rules': JSON.parse(this.gethotelalls[i].rules), 'cancelationpolicy': JSON.parse(this.gethotelalls[i].cancelationpolicy), 'activestatus': this.gethotelalls[i].activestatus });
      }
    })
  }
  item: any;

  gotorooms(item) {
    this.router.navigate(['/bookingform'], { 
      queryParams: { 
        fromdate: this.fromdate, 
        todate: this.todate, 
        hotel_id: item.i, // Corrected this.item to item
        booking_hotel_name: item.name // Corrected this.item to item
      } 
    });
  }
  
}
