import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/pages/service.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  page: number = 1;

  gallerydata: any;

  constructor(private service:ServiceService) { }

  ngOnInit(): void {
    this.getgallery();
  }

  getgallery() {
    this.service.getCatenamedata().subscribe((res: any) => {
      
      this.gallerydata = res.data;
    })

  }

}
