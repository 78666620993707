<app-navbar-style-one></app-navbar-style-one>
<br><br><br>
<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-area">
    <div class="container">
        <div class="contact-max">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-position"></i>
                        <h3>Araku - Visakhapatnam Rd, Araku, Visakhapatnam, </h3>
                        <h3>Andhra Pradesh, 531149, India .</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-email"></i>
                        <h3><a href="mailto:arakutourismart.com@gmail.com">Email : Arakutourismart.com@gmail.com</a></h3>

                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="contact-card">
                        <i class="flaticon-to-do-list"></i>
                        <h3><a href="tel:+(91)9441201920"> Ph no : 9441201920</a></h3>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15149.562575489259!2d82.86371723670288!3d18.329454369399514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3a4a4c298a218d%3A0x2b8de4f914b5f996!2sAraku%20Valley%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1679734399025!5m2!1sen!2sin"
            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="contact-wrap">
            <div class="contact-form">
                <span>SEND MESSAGE</span>
                <h2>Contact With Us</h2>

                <form [formGroup]="submitform">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="text" name="name" id="name" class="form-control" formControlName="name"
                                    required placeholder="Your Name*">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="email" name="email" id="email" class="form-control" formControlName="email"
                                    required placeholder="E-mail*">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-phone'></i>
                                <input type="text" name="phone_number" id="phone_number" formControlName="phonenumber"
                                    required class="form-control" placeholder="Your Phone" maxlength="10"
                                    (keypress)="numericOnly($event)" minlength="10">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-file'></i>
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                    formControlName="subject" required placeholder="Your Subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <i class='bx bx-envelope'></i>
                                <textarea name="message" class="form-control" id="message" formControlName="msg"
                                    required placeholder="Your Message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn border-radius" (click)="submit()">Send
                                Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="my-overlay" *ngIf="showSpinner">
    <div class="cusloader"></div>
    <h5 style="color: white;">Please Wait...</h5>
</div>
<br>
<app-footer-style-two></app-footer-style-two>